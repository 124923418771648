import React from 'react';

export const customLabelClassName: React.CSSProperties = {
    width: '120px',
    textAlign: 'left',
    backgroundColor: 'var{--td-bg-color-container}',
};
export const customContentClassName: React.CSSProperties = {
    textAlign: 'left',
    backgroundColor: 'var{--td-bg-color-container}',
};

export const noPaddingContentClassName: React.CSSProperties = {
    textAlign: 'left',
    padding: 0,
    backgroundColor: 'var{--td-bg-color-container}',
};

export const customItemClassName: React.CSSProperties = {
    textAlign: 'left',
    padding: '12px 16px',
    backgroundColor: 'var{--td-bg-color-container}',
    borderRadius: '4px',
};

export const getAccountTypeTagTheme = (type: string | undefined): 'success' | 'warning' | 'default' | 'primary' | 'danger' | undefined => {
    switch (type) {
        case 'contract':
            return 'primary';
        case 'normal':
            return 'default';
        default:
            return 'warning'
            }
};
