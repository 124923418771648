import React from 'react';
import { Button, Dropdown, DropdownOption } from 'tdesign-react';
import { SwapIcon } from 'tdesign-icons-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setNetwork } from '../../store/slices/globalSlice';

const NetworkToggleButton: React.FC = () => {
  const theme = useAppSelector((state) => state.global.theme);
  const dispatch = useAppDispatch();
  const network = useAppSelector((state) => state.global.network);
  const { t } = useTranslation();

  const options = [
    {
      content: t('network.mainnet'),
      value: 'mainnet',
    },
    {
      content: t('network.testnet'),
      value: 'testnet',
    },
  ];

  const handleNetworkChange = (dropdownItem: DropdownOption) => {
    if (typeof dropdownItem.value === 'string') {
      dispatch(setNetwork(dropdownItem.value as 'mainnet' | 'testnet'));
    }
  };

  return (
    <Dropdown
      options={options}
      onClick={handleNetworkChange}
      // trigger="click"
      popupProps={{ overlayStyle: { width: '100px' } }}
    >
      <Button
        shape="circle"
        variant="text"
        icon={<SwapIcon />}
        theme= {theme === 'light' ? 'default' : 'primary'}
      />
    </Dropdown>
  );
};

export default NetworkToggleButton;