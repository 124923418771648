import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'tdesign-react';
import { useTranslation } from 'react-i18next';
import type { MenuValue } from 'tdesign-react';
import DrawerMenu from './Drawer';
import { MENU_ITEMS } from 'layouts/utils';
import LanguageToggleButton from 'components/LanguageToggle';
import NetworkToggleButton from 'components/NetworkToggle';
import ThemeToggleButton from 'components/ThemeToggle';
import UserButton from 'components/UserButton';

const { HeadMenu, MenuItem } = Menu;

interface HeaderMenuProps {
    theme: 'dark' | 'light' | 'system' | 'blue';
}

const HeaderMenuConsole: React.FC<HeaderMenuProps> = ({ theme }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  // 检测屏幕尺寸变化
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // 初始检查
    checkScreenSize();
    
    // 添加窗口大小变化监听器
    window.addEventListener('resize', checkScreenSize);
    
    // 清理监听器
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleMenuChange = React.useCallback(
    (value: MenuValue) => {
      if (typeof value === 'string') {
        navigate(value);
      }
    },
    [navigate]
  );

  const operationsDark = () => (
    <div className="tdesign-demo-menu__operations--dark">
      <ThemeToggleButton />
      <LanguageToggleButton />
      <NetworkToggleButton />
      <UserButton theme={theme} />
    </div>
  );

  const logo = (
    <div
      style={{
        fontSize: '20px',
        fontWeight: 'bold',
        color: theme === 'dark' ? '#fff' : 'inherit',
      }}
    >
      {t('header.title')}
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 16px',
            height: '64px',
            backgroundColor: theme === 'dark' ? '#242424' : '#fff',
            boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
          }}
        >
          <DrawerMenu theme={theme} />
          {/* <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: theme === 'dark' ? '#fff' : 'inherit',
            }}
          >
            {t('header.title')}
          </div> */}
          {operationsDark()}
        </div>
      ) : (
        <HeadMenu
          value={location.pathname}
          onChange={handleMenuChange}
          // theme={theme === 'dark' ? 'dark' : 'light'}
          logo={logo}
          operations={operationsDark()}
          style={{
            // backgroundColor: "#888",
          }}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.path} value={item.path}>
              {t(item.label)}
            </MenuItem>
          ))}
        </HeadMenu>
      )}
    </>
  );
};

export default HeaderMenuConsole;