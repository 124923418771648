import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ThemeType = 'light' | 'dark' | 'system';
type LanguageType = 'zh-CN' | 'en-US';
type NetworkType = 'mainnet' | 'testnet';

interface UserInfo {
  address?: string;
  privateKey?: string;
  avatar?: string;
  username?: string;
}

interface GlobalState {
  theme: ThemeType;
  language: LanguageType;
  network: NetworkType;
  isLoggedIn: boolean;
  userInfo: UserInfo | null;
  isMobile: boolean;
  transaction: any;
  block: any;
}

// 获取存储的主题或默认主题
const getInitialTheme = (): ThemeType => {
  const savedTheme = localStorage.getItem('theme') as ThemeType;
  if (savedTheme && ['light', 'dark', 'system'].includes(savedTheme)) {
    return savedTheme;
  }
  return 'system';
};

// 修改 initialState
const initialState: GlobalState = {
  theme: getInitialTheme(),
  language: (localStorage.getItem('language') as LanguageType) || 'zh-CN',
  network: (localStorage.getItem('network') as NetworkType) || 'mainnet',
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  userInfo: JSON.parse(localStorage.getItem('userInfo') || 'null'),
  isMobile: false,
  transaction: null,
  block: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload;
      // 主题的实际应用逻辑已移至 ThemeToggle 组件
      // 这里只负责状态管理
      localStorage.setItem('theme', action.payload);
    },
    setLanguage(state, action: PayloadAction<LanguageType>) {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    setNetwork(state, action: PayloadAction<NetworkType>) {
      state.network = action.payload;
      localStorage.setItem('network', action.payload);
    },
    // 修改 reducers
    setLoginState(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
      localStorage.setItem('isLoggedIn', String(action.payload));
    },
    setUserInfo(state, action: PayloadAction<UserInfo | null>) {
      state.userInfo = action.payload;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    setIsMobileState(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = null;
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userInfo');
    },
  },
});

export const {
  setTheme,
  setLanguage,
  setNetwork,
  setLoginState,
  setUserInfo,
  logout
} = globalSlice.actions;

export default globalSlice.reducer;