import React, { memo, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Layout } from "tdesign-react";
import { useAppSelector } from "../hooks/useAppSelector";
import ErrorBoundary from "../components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import HeaderMenu from "./component/HeaderMenu";
import Aside from "tdesign-react/es/layout/Aside";
import Sidebar from "components/SiderBar";
import HeaderMenuConsole from "./component/HeaderMenuConsole";

const { Header, Content, Footer } = Layout;

const MainLayout: React.FC = () => {
  const theme = useAppSelector((state) => state.global.theme);
  const isLoggedIn = useAppSelector((state) => state.global.isLoggedIn);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  let padding = "24px";

  // 检测屏幕尺寸变化
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // 初始检查
    checkScreenSize();

    // 添加窗口大小变化监听器
    window.addEventListener("resize", checkScreenSize);

    // 清理监听器
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  if (isMobile) {
    padding = "12px";
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          padding: 0,
          height: "auto",
          // boxShadow: "none",
        }}
      >
        {!isLoggedIn ? (
          <HeaderMenu theme={theme} />
        ) : (
          <HeaderMenuConsole theme={theme} />
        )}
      </Header>

      <Layout>
        {isLoggedIn && (
          <Aside>
            <Sidebar />
          </Aside>
        )}
        <Layout>
          <Content
            style={{
              padding: 0,
              // minHeight: "calc(100vh - 200px)",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Content>
          {!isLoggedIn && (
            <Footer
              style={{
                textAlign: "right",
                padding: "10px 24px",
              }}
            >
              {t("footer.copyright")}
            </Footer>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default memo(MainLayout);
