import React from "react";
import { Button, Avatar, Popup } from "tdesign-react";
import { UserIcon } from "tdesign-icons-react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { logout } from "store/slices/globalSlice";
import { useTranslation } from "react-i18next";

const UserButton: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
  };

  if (!isLoggedIn) {
    return (
      <Button
        variant="text"
        shape="square"
        icon={<UserIcon />}
        onClick={handleClick}
      />
    );
  }

  return (
    <Popup
      trigger="hover"
      content={
        <div style={{ width: "200px"}}>
          <div style={{ fontSize: "14px", fontWeight: "bold",padding: "15px"}}>{userInfo?.address || t("user.anonymous")}</div>
          <Button
            theme="danger"
            variant="text"
            // shape="square"
            onClick={() => {
              dispatch(logout());
              navigate("/login");
            }}
          >
            {t("user.logout")}
          </Button>
        </div>
      }
    >
      <Avatar
        image={userInfo?.avatar || "https://tdesign.gtimg.com/site/avatar.jpg"}
        style={{ cursor: "pointer" }}
      />
    </Popup>
  );
};

export default UserButton;
