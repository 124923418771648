import React from "react";
import { Card, Descriptions, Table, Space } from "tdesign-react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "tdesign-icons-react";

interface ContractDetailProps {
  contractAddress?: string;
}

const ContractDetail: React.FC<ContractDetailProps> = ({ contractAddress }) => {
  const { t } = useTranslation();

  const eventColumns = [
    {
      title: t("contract.detail.event.name"),
      colKey: "name",
      width: 150,
    },
    {
      title: t("contract.detail.event.hash"),
      colKey: "hash",
      width: 200,
    },
    {
      title: t("contract.detail.event.block"),
      colKey: "block",
      width: 100,
    },
    {
      title: t("contract.detail.event.timestamp"),
      colKey: "timestamp",
      width: 150,
    },
  ];

  return (
    <Space direction="vertical" style={{ margin: "24px" }} size={"large"}>
      <Card>
        <Descriptions
          title={t("contract.detail.basicInfo")}
          items={[
            {
              label: t("contract.detail.address"),
              content: (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {contractAddress}
                  <CopyIcon style={{ cursor: "pointer" }} />
                </div>
              ),
            },
            { label: t("contract.detail.creator"), content: "-" },
            { label: t("contract.detail.balance"), content: "-" },
            { label: t("contract.detail.transactions"), content: "-" },
            { label: t("contract.detail.createdAt"), content: "-" },
          ]}
        />
      </Card>

      <Card title={t("contract.detail.code")}>
        <pre style={{ background: "#f5f5f5", padding: "16px", borderRadius: "8px" }}>
          {/* Contract source code will be displayed here */}
          -
        </pre>
      </Card>

      <Card title={t("contract.detail.abi")}>
        <pre style={{ background: "#f5f5f5", padding: "16px", borderRadius: "8px" }}>
          {/* Contract ABI will be displayed here */}
          -
        </pre>
      </Card>

      <Card title={t("contract.detail.events")}>
        <Table
          columns={eventColumns}
          data={[]}
          loading={false}
          pagination={{
            total: 0,
            pageSize: 10,
            current: 1,
          }}
          rowKey="hash"
        />
      </Card>
    </Space>
  );
};

export default ContractDetail;