import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../store';

/**
 * 自定义 hook，用于获取类型安全的 dispatch 函数
 * 
 * @returns {AppDispatch} 返回类型安全的 dispatch 函数
 * 
 * @example
 * const dispatch = useAppDispatch();
 * dispatch(someAction());
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
