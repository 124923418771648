import React, { useState } from "react";
import { Input, Button, Space } from "tdesign-react";
import { AddIcon, RemoveIcon } from "tdesign-icons-react";
import "./index.css";

export interface MetadataItem {
  key: string;
  value: string;
}

export interface MetadataFieldsProps {
  value?: MetadataItem[];
  onChange?: (fields: MetadataItem[]) => void;
}

export const MetadataFields: React.FC<MetadataFieldsProps> = ({
  value = [],
  onChange,
}) => {
  const [fields, setFields] = useState<MetadataItem[]>(value);

  const handleFieldsChange = (newFields: MetadataItem[]) => {
    setFields(newFields);
    onChange?.(newFields);
  };

  const addField = () => {
    handleFieldsChange([...fields, { key: "", value: "" }]);
  };

  const removeField = (index: number) => {
    const newFields = fields.filter((_, i) => i !== index);
    handleFieldsChange(newFields);
  };

  const updateField = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    const newFields = fields.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    handleFieldsChange(newFields);
  };

  return (
    <Space direction="vertical" size={"small"}>
      {fields.map((field, index) => (
        <Space key={index} direction="horizontal" size={'small'}>
          <Input
            value={field.key}
            onChange={(value) => updateField(index, "key", value as string)}
            placeholder="键"
          />
          <Input
            value={field.value}
            onChange={(value) => updateField(index, "value", value as string)}
            placeholder="值"
          />
          <Button theme="danger" onClick={() => removeField(index)}>
            -
          </Button>
        </Space>
      ))}
      <Button
        theme="primary"
        variant="text"
        onClick={addField}
        icon={<AddIcon />}
      >
        添加字段
      </Button>
    </Space>
  );
};

export default MetadataFields;
