/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { cpp } from '@codemirror/lang-cpp';
import { go } from '@codemirror/lang-go';
import { EditorView } from '@codemirror/view';

interface CodeEditorProps {
  value: string;
  onChange: (value: string) => void;
  language?: string;
  placeholder?: string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ value, onChange, language = 'javascript', placeholder }) => {
  const getLanguageExtension = () => {
    switch (language) {
      case 'js':
        return javascript();
      case 'javascript':
        return javascript();
      case 'c++':
        return cpp();
      case 'cpp':
        return cpp();
      case 'go':
        return go();
      default:
        return javascript();
    }
  };

  return (
    <CodeMirror
      value={value}
      height='300px'
      // theme='light'
      placeholder={placeholder}
      extensions={[getLanguageExtension(), EditorView.lineWrapping]}
      onChange={onChange}
      style={{
        fontSize: '14px',
        borderRadius: '5px',
        border: '1px solid var(--td-component-border)',
        marginBottom: '18px',
      }}
    />
  );
};

export const mockContractTypes = [
  { label: 'js', value: '0' },
  { label: 'c++', value: '1' },
  { label: 'go', value: '2' },
];

export default CodeEditor;
