import React, { useEffect } from 'react';
import { Button, Dropdown, DropdownOption } from 'tdesign-react';
import { MoonIcon, SunnyIcon, DesktopIcon } from 'tdesign-icons-react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setTheme } from '../../store/slices/globalSlice';
import { useTranslation } from 'react-i18next';

type ThemeType = 'light' | 'dark' | 'system';

const ThemeToggleButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.global.theme) as ThemeType;
  const { t } = useTranslation();

  const options = [
    { content: t('theme.light'), value: 'light', icon: <SunnyIcon /> },
    { content: t('theme.dark'), value: 'dark', icon: <MoonIcon /> },
    { content: t('theme.system'), value: 'system', icon: <DesktopIcon /> },
  ];

  // 应用主题到 DOM
  const applyTheme = (newTheme: ThemeType) => {
    // 保存主题到 localStorage
    localStorage.setItem('theme', newTheme);

    // 根据主题设置 data-theme 属性和 t-dark-mode 类
    const isDark = newTheme === 'dark' || 
      (newTheme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

    document.documentElement.setAttribute('theme-mode', isDark ? 'dark' : 'light');
    document.body.classList.toggle('t-dark-mode', isDark);
  };

  // 处理主题变更
  const handleThemeChange = (dropdownItem: DropdownOption) => {
    const newTheme = dropdownItem.value as ThemeType;
    dispatch(setTheme(newTheme));
    applyTheme(newTheme);
  };

  // 监听系统主题变化
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (theme === 'system') {
        document.documentElement.setAttribute('data-theme', e.matches ? 'dark' : 'light');
        document.body.classList.toggle('t-dark-mode', e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleSystemThemeChange);
    
    // 初始化主题
    applyTheme(theme);

    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, [theme]);

  // 获取当前图标
  const getCurrentIcon = () => {
    switch (theme) {
      case 'light':
        return <SunnyIcon />;
      case 'dark':
        return <MoonIcon />;
      case 'system':
        return <DesktopIcon />;
      default:
        return <SunnyIcon />;
    }
  };

  return (
    <Dropdown options={options} onClick={handleThemeChange}>
      <Button
        shape="circle"
        variant="text"
        icon={getCurrentIcon()}
        theme={theme === 'light' ? 'default' : 'primary'}
      />
    </Dropdown>
  );
};

export default ThemeToggleButton;