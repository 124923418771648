import React, { Fragment } from "react";
import { Menu } from "tdesign-react";
import { useNavigate, useLocation } from "react-router-dom";
import type { MenuValue } from "tdesign-react";
import "./Styles/index.css";
import { menuItemsSider } from "layouts/utils";

const { MenuItem } = Menu;

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
      <Menu
        value={location.pathname}
        onChange={(value: MenuValue) => navigate(value as string)}
        style={{ marginRight: 20, position: "sticky" }}
      >
        {menuItemsSider.map((item) => (
          <MenuItem key={item.path} value={item.path}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
  );
};

export default Sidebar;
