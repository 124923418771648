import { createBrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import MainLayout from '../layouts/MainLayout';
import LoadingPage from '../components/LoadingPage';
import NotFound from '../pages/NotFound';
import Error from '../pages/Error';
import Contracts from 'pages/Contracts';
import ContractDetail from 'pages/Contracts/components/ContractDetail';
import Tokens from 'pages/Tokens';
import TokenDetail from 'pages/Tokens/components/TokenDetail';
import Login from 'pages/Login';
import CreateContract from 'pages/Wallet/CreateContract';
import Register from 'pages/Register';

// 使用 React.lazy 进行代码分割
const Home = React.lazy(() => import('../pages/Home'));
const Blocks = React.lazy(() => import('../pages/Blocks'));
const BlockDetail = React.lazy(() => import('../pages/Blocks/components/BlockDetail'));
const Transactions = React.lazy(() => import('../pages/Transactions'));
const TransactionDetail = React.lazy(() => import('../pages/Transactions/components/TransactionDetail'));
const Accounts = React.lazy(() => import('../pages/Accounts'));
const AccountDetail = React.lazy(() => import('../pages/Accounts/components/AccountDetail'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: 'blocks',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Blocks />
          </Suspense>
        ),
      },
      {
        path: 'blocks/:blockNumber',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <BlockDetail />
          </Suspense>
        ),
      },
      {
        path: 'transactions',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Transactions />
          </Suspense>
        ),
      },
      {
        path: 'transactions/:hash',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <TransactionDetail />
          </Suspense>
        ),
      },
      {
        path: 'accounts',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Accounts />
          </Suspense>
        ),
      },
      {
        path: 'accounts/:address',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <AccountDetail />
          </Suspense>
        ),
      },
      {
        path: 'tokens',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Tokens />
          </Suspense>
        ),
      },
      {
        path: 'tokens/:address',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <TokenDetail />
          </Suspense>
        ),
      },
      {
        path: 'contracts',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Contracts />
          </Suspense>
        ),
      },
      {
        path: 'contracts/:address',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <ContractDetail />
          </Suspense>
        ),
      },
      {
        path: 'tools',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Contracts />
          </Suspense>
        ),
      },
      {
        path: 'login',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: 'register',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <Register />
          </Suspense>
        ),
      },
      {
        path: 'testMethod',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CreateContract />
          </Suspense>
        ),
      },
      {
        path: 'createContract',
        element: (
          <Suspense fallback={<LoadingPage />}>
            <CreateContract />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
