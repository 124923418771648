import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "tdesign-react";
import zhCN from "tdesign-react/es/locale/zh_CN";
import enUS from "tdesign-react/es/locale/en_US";
import { useTranslation } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import router from "./router";
import { setTheme } from "./store/slices/globalSlice";
import ErrorBoundary from "./components/ErrorBoundary";
import "./styles/global.css";
import "./styles/card.css";
import "./styles/custom.css";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  // 初始化主题
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as "light" | "dark" | null;
    if (savedTheme) {
      store.dispatch(setTheme(savedTheme));
      if (savedTheme === "dark") {
        document.body.classList.add("t-dark-mode");
      }
    } else {
      // 根据系统主题设置初始主题
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      store.dispatch(setTheme(prefersDark ? "dark" : "light"));
      if (prefersDark) {
        document.body.classList.add("t-dark-mode");
      }
    }
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider
            globalConfig={i18n.language === "zh-CN" ? zhCN : enUS}
          >
            <RouterProvider router={router} />
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
