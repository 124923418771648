import React, { useEffect, useState } from "react";
import { Card, Descriptions, Table, Space, Tabs } from "tdesign-react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "tdesign-icons-react";
import { CopyableText } from "components/CopyableText";
import { assetsService } from "services/modules/assets";
import { useParams, Link } from "react-router-dom";
import TokenHolders from "components/TokenHolders/TokenHolders";
import { customLabelClassName, customContentClassName } from "utils/custom";

const { TabPanel } = Tabs;

interface Token {
  _id: string;
  decimals?: number;
  address?: string;
  name?: string;
  code: string;
  issuer?: string;
  symbol?: string;
  amount: string;
  totalSupply?: string;
  holders: [
    {
      address: string;
      amount: string;
    }
  ];
  transfers?: number;
  holdersCount?: number;
}

interface Transfer {
  hash: string;
  from: string;
  to: string;
  amount: string;
  timestamp: string;
}

interface TokenDetailProps {
  token?: Token;
  transfers?: Transfer[];
  totalTransfers?: number;
}

const TokenDetail: React.FC<TokenDetailProps> = () => {
  const { t } = useTranslation();
  const { address } = useParams<{ address: any }>();
  const [token, setToken] = useState({} as Token);
  const [transfers, setTransfers] = useState([] as Transfer[]);
  const [totalTransfers, setTotalTransfers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const params = atob(address).split(':');
  let assetId = '';
  let code = '';
  let issuer = '';
  if (params.length === 1) {
    assetId = params[0];
  } else if (params.length === 2) {
    code = params[0];
    issuer = params[1];
  }
  // const [code, issuer, _id] = params;
  // const assetId = _id;
  // console.log("tokenData params",params, code, issuer, assetId);


  useEffect(() => {
    const fetchTokenData = async () => {
      // if (!assetId || (!code && !issuer)) return;

      try {
        setLoading(true);
        const tokenData = await assetsService.getAsset(assetId, code, issuer);
        console.log("tokenData", tokenData);
        if (!tokenData) return;
        setToken(tokenData);

        // 这里可以添加获取transfers数据的API调用
        // const transfersData = await getTokenTransfers(initialToken.address);
        // setTransfers(transfersData.records);
        // setTotalTransfers(transfersData.total);
      } catch (err: any) {
        setError(err);
        console.error("Failed to fetch token data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenData();
  }, [assetId]);

  const transferColumns = [
    {
      title: t("token.detail.transfer.hash"),
      colKey: "hash",
      width: 200,
    },
    {
      title: t("token.detail.transfer.from"),
      colKey: "from",
      width: 200,
    },
    {
      title: t("token.detail.transfer.to"),
      colKey: "to",
      width: 200,
    },
    {
      title: t("token.detail.transfer.amount"),
      colKey: "amount",
      width: 150,
    },
    {
      title: t("token.detail.transfer.timestamp"),
      colKey: "timestamp",
      width: 150,
    },
  ];

  return (
    <Space direction="vertical" style={{ margin: "24px" }}>
      <Card
        title={token.code || t("token.detail.title")}
        subtitle={token.issuer}
        bordered={false}
        style={{ borderRadius: 0 }}
      >
        <Tabs defaultValue="basic" placement="top" theme="card">
          <TabPanel value="basic" label={t("token.detail.basicInfo")}>
            {/* <Card> */}
            <Descriptions
              colon
              itemLayout="horizontal"
              layout="vertical"
              size="large"
              bordered
              labelStyle={customLabelClassName}
              contentStyle={customContentClassName}
              // title={t("token.detail.basicInfo")}
              items={[
                {
                  label: t("token.detail.address"),
                  content: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Link to={`/accounts/${token?.issuer}`}>
                        {token?.issuer}
                      </Link>
                      <CopyIcon style={{ cursor: "pointer" }} />
                    </div>
                  ),
                },
                { label: t("token.detail.name"), content: token.name || "-" },
                { label: t("token.detail.symbol"), content: token.code || "-" },
                {
                  label: t("token.detail.totalSupply"),
                  content: token.totalSupply || "-",
                },
                {
                  label: t("token.detail.holders"),
                  content: token.holdersCount?.toString() || "-",
                },
                {
                  label: t("token.detail.transfers"),
                  content: token.transfers?.toString() || "-",
                },
              ]}
            />
            {/* </Card> */}
          </TabPanel>

          <TabPanel value="holders" label={t("token.detail.holders")}>
            <TokenHolders holders={token.holders || []} totalSupply={Number(token.totalSupply) || 0} />
            {/* 持有者列表排行：资产名称 资产代码  资产转账地址 持有者持有数量 */}

          </TabPanel>

          <TabPanel value="transfers" label={t("token.detail.transferHistory")}>
            {/* <Card title={t("token.detail.transferHistory")} subtitle={token.name || token.code || ""}> */}
            <Table
              columns={transferColumns.map((col) => {
                if (col.colKey === "hash") {
                  return {
                    ...col,
                    cell: ({ row }: { row: Transfer }) => (
                      <Link to={`/transactions/${row.hash}`}>{row.hash}</Link>
                    ),
                  };
                }
                if (col.colKey === "from" || col.colKey === "to") {
                  return {
                    ...col,
                    cell: ({ row }: { row: Transfer }) => (
                      <Link
                        to={`/accounts/${row[col.colKey as keyof Transfer]}`}
                      >
                        {row[col.colKey as keyof Transfer]}
                      </Link>
                    ),
                  };
                }
                return col;
              })}
              data={transfers}
              loading={!transfers}
              pagination={{
                total: totalTransfers,
                pageSize: 10,
                current: 1,
              }}
              rowKey="hash"
            />
            {/* </Card> */}
          </TabPanel>
        </Tabs>
      </Card>
    </Space>
  );
};

export default TokenDetail;
