import request from '../../utils/request';
import chainRequest from '../../utils/chainRequest';
import type { AssetsListParams, Asset, Assets } from '../../types/api';

export const assetsService = {
  // 获取账户列表
  getAssets: async (params: AssetsListParams) => {
    // console.log('getAssets', params);
    const res = await request.get<Assets>('/api/v1/assets', params);
    console.log('getAssets', res);
    return res;
  },

  // 获取账户详情
  getAsset: async (assetId?: string, code?: string, issuer?: string) => {
    // console.log('getAsset', assetId, code, issuer);
    let params: any = {};
    if (assetId) {
      params.assetId = assetId;
    }
    if (code && issuer) {
      params.code = code;
      params.issuer = issuer;
    }
  
    // console.log('getAsset', params);
    const res = await request.get<any>('/api/v1/asset', params);
    // console.log('getAsset', res);
    res.totalSupply = res.amount;
    return res;
  },
};
