import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, MessagePlugin } from 'tdesign-react';
import { creatAccount } from '../../services/wallet/blockchain';
import './styles/index.css';

const { FormItem } = Form;

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleRegister = async () => {
    try {
      const account = await creatAccount();
      console.log('账户创建成功:', account);
      MessagePlugin.success('账户创建成功');
      // 可以在这里保存账户信息或直接跳转到登录页
      navigate('/login');
    } catch (error: any) {
      MessagePlugin.error(error.message || '账户创建失败');
    }
  };

  return (
    <div className="register-container">
      <Card className="register-card">
        <h2 className="register-title">创建新账户</h2>
        <div className="register-content">
          <p className="register-description">
            点击下方按钮创建新的区块链账户。创建成功后，请务必保存好您的私钥信息。
          </p>
          <Button theme="primary" block onClick={handleRegister}>
            创建账户
          </Button>
        </div>
        <div className="register-footer">
          <Button theme="default" variant="text" onClick={() => navigate('/login')}>
            返回登录
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Register;