import React, { useEffect, useState } from "react";
import {
  Form,
  MessagePlugin,
  Dialog,
  Select,
  Input,
  Button,
  FormInstanceFunctions,
} from "tdesign-react";
import { AddIcon } from "tdesign-icons-react";
import { SelectValue, SelectOption } from "tdesign-react/es/select/type";
import CodeEditor, { mockContractTypes } from "../CodeEditor";
import {
  mockAddressesFrom,
  mockAddressesTo,
  mockMethodOptions,
} from "mock/baseData";
import MetadataFields from "components/MetadataFields";

const { FormItem } = Form;

interface TransferFormData {
  type: string;
  from: string;
  to: string;
  amount: number;
  privKey: string;
  metadata?: string;
}

interface TransferDialogProps {
  visible: boolean;
  initialValues?: Partial<TransferFormData>;
  type?: string;
  onClose: () => void;
  onSubmit: (values: TransferFormData) => Promise<void>;
}

const payCoinItems = [
  {
    label: "转出地址",
    name: "from",
    rules: [{ required: true, message: "请输入转出地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请输入转出地址" />
    ),
  },
  {
    label: "接收地址",
    name: "to",
    rules: [{ required: true, message: "请输入接收地址" }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择接收地址" />
    ),
  },
  {
    label: "转移数量",
    name: "amount",
    rules: [
      { required: true, message: "请输入转账金额" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的转账金额",
      },
    ],
    component: <Input type="number" placeholder="请输入转账金额" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const createAccountItems = [
  {
    label: "创建地址",
    name: "from",
    rules: [{ required: true, message: "请输入创建地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择创建地址" />
    ),
  },
  {
    label: "接收地址",
    name: "to",
    // rules: [{ required: true, message: '请输入接收地址' }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择接收地址" />
    ),
  },
  {
    label: "转移数量",
    name: "amount",
    rules: [
      { required: true, message: "请输入转账金额" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的转账金额",
      },
    ],
    component: <Input type="number" placeholder="请输入转账金额" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const createContractAccountByGasItems = [
  {
    label: "创建地址",
    name: "from",
    rules: [{ required: true, message: "请输入创建地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择创建地址" />
    ),
  },
  {
    label: "合约地址",
    name: "to",
    rules: [{ required: true, message: "请输入合约地址" }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择合约地址" />
    ),
  },
  {
    label: "转移数量",
    name: "amount",
    rules: [
      { required: true, message: "请输入转账金额" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的转账金额",
      },
    ],
    component: <Input type="number" placeholder="请输入转账金额" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const createContractAccountByAssetsItems = [
  {
    label: "创建地址",
    name: "from",
    rules: [{ required: true, message: "请输入创建地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择创建地址" />
    ),
  },
  {
    label: "合约地址",
    name: "to",
    rules: [{ required: true, message: "请输入接收地址" }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择接收地址" />
    ),
  },
  {
    label: "转移数量",
    name: "amount",
    rules: [
      // { required: true, message: '请输入转账金额' },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的转账金额",
      },
    ],
    component: <Input type="number" placeholder="请输入资产数量" />,
  },
  {
    label: "资产编码",
    name: "code",
    // rules: [{ required: true, message: '资产编码' }],
    component: <Input placeholder="资产编码" />,
  },
  {
    label: "资产发行账户",
    name: "issuer",
    // rules: [{ required: true, message: '资产编码' }],
    component: <Input placeholder="资产发行账户" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const createContractItems = [
  {
    label: "创建地址",
    name: "from",
    rules: [{ required: true, message: "请输入合约OWNER地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择合约OWNER地址" />
    ),
  },
  {
    label: "初始化Gas",
    name: "amount",
    rules: [
      {
        required: true,
        message:
          "必填，给合约账户的初始化资产，1 Gas = 10^8 UGas, 大小限制[1, max(int64)]",
      },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的Gas数量",
      },
    ],
    component: (
      <Input
        type="number"
        placeholder="必填，给合约账户的初始化资产，单位UGas，1 Gas = 10^8 UGas, 大小限制[1, max(int64)]"
      />
    ),
  },
  {
    label: "代码语言",
    name: "codeType",
    rules: [{ required: true, message: "代码语言类型" }],
    component: (
      <Select options={mockContractTypes} placeholder="代码语言类型" />
    ),
  },
  {
    label: "合约代码",
    name: "payload",
    rules: [{ required: true, message: "payload" }],
    component: (form: FormInstanceFunctions<any>) => (
      <CodeEditor
        placeholder="请输入合约代码"
        language={
          mockContractTypes.find(
            (t) => t.value === form.getFieldValue("codeType")
          )?.label || "javascript"
        }
        value={form.getFieldValue("payload")?.toString() || ""}
        onChange={(value) => form.setFieldsValue({ payload: value })}
      />
    ),
  },
  {
    label: "合约初始化",
    name: "initInput",
    // rules: [{ required: true, message: 'initInput' }],
    component: <Input type="text" placeholder="合约初始化命令" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const issueAssetsItems = [
  {
    label: "发行地址",
    name: "from",
    rules: [{ required: true, message: "请输入发行地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择发行地址" />
    ),
  },
  {
    label: "资产编码",
    name: "code",
    rules: [{ required: true, message: "资产编码" }],
    component: <Input placeholder="资产编码" />,
  },
  {
    label: "发行数量",
    name: "amount",
    rules: [
      { required: true, message: "请输入转账金额" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的转账金额",
      },
    ],
    component: <Input type="number" placeholder="请输入转账金额" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const payAssetsItems = [
  {
    label: "转出地址",
    name: "from",
    rules: [{ required: true, message: "请输入转出地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择转出地址" />
    ),
  },
  {
    label: "接收地址",
    name: "to",
    rules: [{ required: true, message: "请输入接收地址" }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择接收地址" />
    ),
  },
  {
    label: "资产编码",
    name: "code",
    rules: [{ required: true, message: "资产编码" }],
    component: <Input placeholder="资产编码" />,
  },
  {
    label: "资产发行账户",
    name: "issuer",
    rules: [{ required: true, message: "资产发行账户地址" }],
    component: <Input type="password" placeholder="资产发行账户地址" />,
  },
  {
    label: "资产数量",
    name: "amount",
    rules: [
      { required: true, message: "资产数量" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的资产数量",
      },
    ],
    component: <Input type="number" placeholder="资产数量" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const setMetadataItems = [
  {
    label: "设置地址",
    name: "from",
    rules: [{ required: true, message: "请输入设置地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择设置地址" />
    ),
  },
  // {
  //   label: '接收地址',
  //   name: 'to',
  //   rules: [{ required: true, message: '请输入接收地址' }],
  //   component: <Input placeholder='请输入接收地址' />,
  // },
  {
    label: "key",
    name: "key",
    rules: [{ required: true, message: "字段名称" }],
    component: <Input type="text" placeholder="字段名称" />,
  },
  {
    label: "value",
    name: "value",
    rules: [{ required: true, message: "字段值" }],
    component: <Input type="text" placeholder="字段值" />,
  },
  {
    label: "版本",
    name: "version",
    rules: [{ required: true, message: "版本" }],
    component: <Input type="text" placeholder="版本" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
  {
    label: "备注",
    name: "metadata",
    component: <MetadataFields />,
  },
];
const setPermissionItems = [
  {
    label: "设置地址",
    name: "from",
    rules: [{ required: true, message: "请输入设置地址" }],
    component: (
      <Select options={mockAddressesFrom} placeholder="请选择设置地址" />
    ),
  },
  {
    label: "接收地址",
    name: "to",
    rules: [{ required: true, message: "请选择接收地址" }],
    component: (
      <Select options={mockAddressesTo} placeholder="请选择接收地址" />
    ),
  },
  {
    label: "交易阈值",
    name: "txThreshold",
    rules: [
      { required: true, message: "交易阈值" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的交易阈值",
      },
    ],
    component: <Input type="number" placeholder="交易阈值" />,
  },
  {
    label: "指定类型交易阈值",
    name: "typeThreshold",
    rules: [
      { required: true, message: "指定类型交易阈值" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的交易阈值",
      },
    ],
    component: <Input type="number" placeholder="指定类型交易阈值" />,
  },
  {
    label: "账户自身权重",
    name: "masterWeight",
    rules: [
      { required: true, message: "账户自身权重" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的权重值",
      },
    ],
    component: <Input type="number" placeholder="账户自身权重" />,
  },
  {
    label: "签名者权重",
    name: "signers",
    rules: [
      { required: true, message: "签名者权重" },
      {
        validator: (val: number) => val >= 0 && val <= Number.MAX_SAFE_INTEGER,
        message: "请输入有效的权重值",
      },
    ],
    component: <Input type="number" placeholder="签名者权重" />,
  },
  {
    label: "私钥",
    name: "privateKey",
    rules: [{ required: true, message: "请填写私钥" }],
    component: <Input type="password" placeholder="测试使用" />,
  },
];

const TransferDialog = ({
  visible,
  initialValues = {},
  type,
  onClose,
  onSubmit,
}: TransferDialogProps) => {
  const [form] = Form.useForm();
  const [currentType, setCurrentType] = useState("7");

  useEffect(() => {
    if (visible) {
      form.reset();
      if (type) {
        setCurrentType(type);
      }
      form.setFieldsValue({ ...initialValues, type: type || currentType });
    }
  }, [visible, type]);

  const getFormItems = (type: string) => {
    switch (type) {
      case "1":
        return createAccountItems;
      case "11":
        return createContractAccountByGasItems;
      case "111":
        return createContractAccountByAssetsItems;
      case "1111":
        return createContractItems;
      case "2":
        return issueAssetsItems;
      case "3":
        return payAssetsItems;
      case "4":
        return setMetadataItems;
      case "7":
        return payCoinItems;
      case "9":
        return setPermissionItems;
      default:
        return payCoinItems;
    }
  };

  const handleTypeChange = (value: SelectValue<SelectOption>) => {
    const newType = value as string;
    setCurrentType(newType);
    form.reset();
    form.setFieldsValue({ type: newType });
  };

  const handleConfirm = async () => {
    try {
      await form.validate();
      const values = form.getFieldsValue(true);
      console.log("提交的表单数据:", values);

      const amountNum = Number(values.amount);
      // if (Number.isNaN(amountNum) || amountNum < 0 || amountNum > Number.MAX_SAFE_INTEGER) {
      //   MessagePlugin.warning('请输入有效的资产数量（0 ~ 9007199254740991）');
      //   return;
      // }

      // const metadata = values.metadata
      //   ?.map((item: MetadataItem) => ({
      //     key: item.key.trim(),
      //     value: item.value.trim(),
      //   }))
      //   .filter((item: MetadataItem) => item.key !== '' || item.value !== '');

      await onSubmit({
        ...values,
        amount: amountNum,
        type: values.type,
        // metadata: metadata ? JSON.stringify(metadata) : '',
      } as unknown as TransferFormData);

      onClose();
    } catch (error: any) {
      MessagePlugin.error(error?.message || "提交失败");
    }
  };

  return (
    <Dialog
      width={"60%"}
      header="发起转账"
      visible={visible}
      onClose={onClose}
      onConfirm={handleConfirm}
      mode="modal"
      placement="center"
      showOverlay
      theme="default"
      preventScrollThrough
    >
      <Form form={form} layout="vertical" statusIcon>
        <FormItem
          label="操作类型"
          name="type"
          rules={[{ required: true, message: "请选择操作类型" }]}
        >
          <Select value={currentType} onChange={handleTypeChange}>
            {mockMethodOptions.map((v, i) => (
              <Select.Option key={i} value={v.value} label={v.label} />
            ))}
          </Select>
        </FormItem>
        {getFormItems(currentType).map((v, i) => (
          <FormItem key={i} label={v.label} name={v.name} rules={v.rules}>
            {v.component}
          </FormItem>
        ))}
      </Form>
    </Dialog>
  );
};

export default TransferDialog;
