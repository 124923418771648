import React from "react";
import { Card, Table, Space, Input } from "tdesign-react";
import { useTranslation } from "react-i18next";
import { ceil } from "lodash";
import { formatAssets, formatNumber } from "utils/format";
import { Cell } from "tdesign-mobile-react";

interface TokenHolder {
  address: string;
  amount: string;
}

interface TokenHoldersProps {
  holders: TokenHolder[];
  totalSupply: number;
}

const TokenHolders: React.FC<TokenHoldersProps> = ({ holders, totalSupply }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState("");

  const columns = [
    {
      title: t("token.holders.address"),
      colKey: "address",
      width: 300,
    },
    {
      title: t("token.holders.balance"),
      colKey: "amount",
      width: 150,
      cell: ({ row }: { row: TokenHolder }) => {
        return formatNumber(Number(row.amount)/10000000);
      }
    },
    {
      title: t("token.holders.percentage"),
      colKey: "percentage",
      width: 150,
      cell: ({ row }: { row: TokenHolder }) => {
        return ((Number(row.amount)/totalSupply)*100).toFixed(4) + "%";
    },
    }
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* <Card bordered={false}> */}
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder={t("token.holders.searchPlaceholder")}
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
          />
          <Table
            columns={columns}
            data={holders}
            loading={false}
            pagination={{
              total: 0,
              pageSize: 10,
              current: 1,
            }}
            rowKey="address"
          />
        </Space>
      {/* </Card> */}
    </Space>
  );
};

export default TokenHolders;