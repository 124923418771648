import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, Button, Col, Input, Row, MessagePlugin, Layout, Space } from 'tdesign-react';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { getTransactionInfo, getBlockTransactions, transfer } from 'modules/chain/transaction';
import TransferDialog from 'components/TransferDialog';
import { SearchIcon } from 'tdesign-icons-react';

// const { Content } = Layout;

// 定义列配置类型
interface TableColumn {
  colKey: string;
  title: string;
  width?: number;
}

const columns: TableColumn[] = [
  { colKey: 'hash', title: '交易哈希', width: 200 },
  { colKey: 'timestamp', title: '时间戳', width: 180 },
  { colKey: 'from', title: '发送方', width: 200 },
  { colKey: 'to', title: '接收方', width: 200 },
  { colKey: 'value', title: '交易金额', width: 150 },
  { colKey: 'status', title: '状态', width: 100 },
  { colKey: 'type', title: '交易类型', width: 120 },
  { colKey: 'metadata', title: '备注', width: 150 },
];

// 定义分页参数类型
interface PaginationParams {
  current: number;
  pageSize: number;
}

const CreateContract = () => {
  const dispatch = useAppDispatch();
  const [transferVisible, setTransferVisible] = useState(false);
  const [searchHash, setSearchHash] = useState('');

  // const { data, pagination, loading } = useAppSelector((state: RootState) => ({
  //   data: state.global.transaction,
  //   // pagination: state.global.transaction.pagination,
  //   // loading: state.global.transaction.loading,
  // }));

  useEffect(() => {
    // dispatch(getList({ current: 1, pageSize: 10 }));
  }, [dispatch]); // 添加dispatch到依赖数组

  const onChange = ({ current, pageSize }: PaginationParams) => {
    // dispatch(getList({ current, pageSize }));
  };

  // // 处理可能的undefined值
  // const safePagination = {
  //   total: pagination?.total || 0,
  //   pageSize: pagination?.pageSize || 10,
  // };

  // const handleSearch = (value: string) => {
  //   setSearchHash(value);
  //   if (value) {
  //     dispatch(getTransactionInfo(value));
  //   } else {
  //     // dispatch(getList({ current: 1, pageSize: 10 }));
  //   }
  // };

  return (
    <Space direction='vertical' style={{ padding: '24px' }}>
        <Row justify='space-between'>
          <Col>
            <Row gutter={8} align='middle'>
              <Col>
                <Button onClick={() => setTransferVisible(true)}>发起操作</Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Input
              value={searchHash}
              onChange={(value) => setSearchHash(value)}
              // onEnter={() => handleSearch(searchHash)}
              // suffixIcon={<SearchIcon onClick={() => handleSearch(searchHash)} />}
              placeholder='请输入交易哈希查询'
            />
          </Col>
        </Row>

        <TransferDialog
          visible={transferVisible}
          onClose={() => setTransferVisible(false)}
          onSubmit={async (values) => {
            try {
              await dispatch(transfer(values)).unwrap();
              MessagePlugin.success('转账发起成功');
              // dispatch(getList({ current: 1, pageSize: 10 }));
            } catch (error: any) {
              MessagePlugin.error(`转账失败: ${error?.message || '未知错误'}`);
            }
          }}
        />
{/* 
        <Table
          rowKey='height'
          columns={columns}
          // data={data || []} // 处理空数据情况
          // size='medium'
          // hover
          // verticalAlign='middle'
        /> */}
        {/* <Pagination
          className='mt-4'
          // total={safePagination.total}
          // pageSize={safePagination.pageSize}
          // pageSizeOptions={[10, 20, 50]}
          // onChange={onChange}
        /> */}
    </Space>
  );
};

export default CreateContract;
