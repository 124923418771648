import React from 'react';
import { createRoot } from 'react-dom/client';
import 'tdesign-react/es/style/index.css';
import './index.css';
// import './styles/orange.css';
import './styles/theme.css';
import './i18n';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
