export const mockAddressesFrom = [
    { label: 'YxLVyL2Rr6u9nFpVdq16ycTJ3HssQBuc9BAVz', value: 'YxLVyL2Rr6u9nFpVdq16ycTJ3HssQBuc9BAVz' },
    { label: 'YxLVdxKt6FJUTXCsdjSCEdMg4dSynBqTqLSnb', value: 'YxLVdxKt6FJUTXCsdjSCEdMg4dSynBqTqLSnb' },
    { label: 'YxLVkMLxmJEtDQEob3SvVnZGjp7XrWSDvKm7a', value: 'YxLVkMLxmJEtDQEob3SvVnZGjp7XrWSDvKm7a' },
    { label: 'YxLVUqbxGozB1rXjLxFUtLnVPg6P9s7thxNxG', value: 'YxLVUqbxGozB1rXjLxFUtLnVPg6P9s7thxNxG' },
];

export const mockAddressesTo = [
    { label: 'YxLW1Vb5sz2DnaNtY3RdVV5XcvVQJKobiFEPz', value: 'YxLW1Vb5sz2DnaNtY3RdVV5XcvVQJKobiFEPz' },
    { label: 'YxLVfRinLpcMtyi52tvb8mAybTgXz5mxxjNFG', value: 'YxLVfRinLpcMtyi52tvb8mAybTgXz5mxxjNFG' },
    { label: 'YxLVtTueWjChHzajuFLQzM9HgP8AyHwRbGK5C', value: 'YxLVtTueWjChHzajuFLQzM9HgP8AyHwRbGK5C' },
    { label: 'YxLVixMC4TS1oxrQfZZZGBYKLfdZQdz6w3N2X', value: 'YxLVixMC4TS1oxrQfZZZGBYKLfdZQdz6w3N2X' },
];

export const mockMethodOptions = [
    { label: '支付', value: '7' },
    { label: '激活账户', value: '1' },
    { label: '激活合约账户（发送Gas）', value: '11' },
    { label: '激活合约账户（发行资产）', value: '111' },
    { label: '发行资产', value: '2' },
    { label: '转移资产', value: '3' },
    { label: '设置metadata', value: '4' },
    { label: '部署合约', value: '1111' },
    { label: '设置权限', value: '9' },
];

export const MethodSelectOptions = [
    { label: '全部', value: '0' },
    { label: '支付', value: '7' },
    { label: '激活账户', value: '1' },
    { label: '激活合约账户（发送Gas）', value: '11' },
    { label: '激活合约账户（发行资产）', value: '111' },
    { label: '发行资产', value: '2' },
    { label: '转移资产', value: '3' },
    { label: '设置metadata', value: '4' },
    { label: '部署合约', value: '1111' },
    { label: '设置权限', value: '9' },
];
