export const MENU_ITEMS = [
    {
        path: '/',
        label: 'header.menu.home',
    },
    {
        path: '/blocks',
        label: 'header.menu.blocks',
    },
    {
        path: '/transactions',
        label: 'header.menu.transactions',
    },
    {
        path: '/accounts',
        label: 'header.menu.accounts',
    },
    {
        path: '/tokens',
        label: 'header.menu.tokens',
    },
    {
        path: '/contracts',
        label: 'header.menu.contracts',
    },
    // {
    //     path: '/tools',
    //     label: 'header.menu.tools',
    // },
];


export const menuItemsSider = [
    { path: "/testMethod", label: "操作测试", icon: 'ViewListIcon' },
    { path: '/transfer', label: '转移资产', icon: `<BookIcon />` },
    { path: '/createAccount', label: '激活账户', icon: `<SwapIcon />` },
    { path: '/createAssets', label: '发行资产', icon: `<AppIcon />` },
    { path: '/createMetadata', label: 'METADATA', icon: `<UserIcon />` },
    { path: "/createContract", label: "部署合约", icon: 'ViewListIcon' },
    { path: '/useContract', label: '调用合约', icon: `<ViewListIcon />` },
    { path: '/createLog', label: '发布博客', icon: `<ViewListIcon />` },
  ];
