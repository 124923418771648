import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import * as blockchainService from 'services/wallet/blockchain';

// 定义状态类型
interface TransactionState {
  data: any[];
  loading: boolean;
  pagination: {
    current: number;
    pageSize: number;
    total: number;
  };
  error: string | null;
}

// 初始状态
const initialState: TransactionState = {
  data: [],
  loading: false,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  error: null,
};

// interface Operation {
//   type: string;
//   create_account?: {
//     dest_address: string;
//     contract?: {
//       payload: string;
//     };
//     init_balance?: number;
//     init_input?: string;
//     metadatas?: Array<{ key: string; value: string; version: number }>;
//     priv?: {
//       master_weight: number;
//       thresholds: {
//         tx_threshold: number;
//       };
//     };
//   };
//   issue_asset?: {
//     amount: number;
//     code: string;
//   };
//   pay_asset?: {
//     dest_address: string;
//     asset: {
//       key: {
//         issuer: string;
//         code: string;
//         type?: number;
//       };
//       amount: number;
//     };
//     input?: string;
//   };
//   set_metadata?: {
//     key: string;
//     value: string;
//     version: number;
//   };
//   pay_coin?: {
//     dest_address: string;
//     amount: number;
//     input?: string;
//   };
//   set_privilege?: {
//     master_weight: string;
//     signers: Array<{ address: string; weight: number }>;
//     tx_threshold: string;
//     type_thresholds: Array<{ type: number; threshold: number }>;
//   };
// }

// // 定义转账参数接口
// interface TransferParams {
//   // from: string;
//   // to: string;
//   // amount: number;
//   // privKey: string;
//   // metadata?: string;
//   // operation: Operation;
//   // operation: {
//   //   type: string;
//   //   create_account?: {
//   //     dest_address?: string;
//   //     contract?: {
//   //       payload: string;
//   //     };
//   //     init_balance?: number;
//   //     init_input?: string;
//   //     priv?: {
//   //       master_weight: number;
//   //       thresholds: {
//   //         tx_threshold: number;
//   //       };
//   //     };
//   //   };
//   //   issue_asset?: {
//   //     code: string;
//   //     amount: number;
//   //   };
//   //   pay_asset?: {
//   //     asset: {
//   //       key: {
//   //         issuer: string;
//   //         code: string;
//   //         type?: number;
//   //       };
//   //       amount: number;
//   //     };
//   //     input?: string;
//   //   };
//   //   set_metadata?: {
//   //     key: string;
//   //     value: string;
//   //     version: number;
//   //   };
//   //   set_privilege?: {
//   //     master_weight: string;
//   //     signers: Array<{ address: string; weight: number }>;
//   //     tx_threshold: string;
//   //     type_thresholds: Array<{ type: number; threshold: number }>;
//   //   };
//   // };
// }

// 异步 action: 获取交易列表
// export const getList = createAsyncThunk(
//   'transaction/getList',
//   async ({ current, pageSize }: { current: number; pageSize: number }) => {
//     const response = await blockchainService.getTrxs({ current, pageSize });
//     return response.result;
//   },
// );

// 异步 action: 获取交易详情
export const getTransactionInfo = createAsyncThunk('transaction/getTransactionInfo', async (hash: string) => {
  const response = await blockchainService.getTransactionInfo(hash);
  return response.result;
});

// 异步 action: 获取区块中的交易
export const getBlockTransactions = createAsyncThunk('transaction/getBlockTransactions', async (height: string) => {
  const response = await blockchainService.getBlockTransactions(height);
  return response.result;
});

// 异步 action: 发送交易
export const sendTransaction = createAsyncThunk('transaction/sendTransaction', async (params: any) => {
  const response = await blockchainService.sendTransaction(params);
  return response.result;
});

// 异步 action: 转账交易
export const transfer = createAsyncThunk('transaction/transfer', async (params: any, { rejectWithValue }) => {
  try {
    // const transferParams = {
    //   from: params.from,
    //   to: params.to,
    //   amount: params.amount,
    //   privateKey: params.privKey,
    //   metadata: params.metadata || '',
    //   operation: params.operation,
    // }; 

    const response = await blockchainService.transfer(params);

    if (!response || !response.result) {
      return rejectWithValue('转账失败：未收到有效响应');
    }

    return response.result;
  } catch (error: any) {
    return rejectWithValue(error.message || '转账失败');
  }
});

/* 创建交易相关的 Redux slice
 * 用于管理区块链交易状态、操作和数据
 * @module chain / transaction
 */
const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 获取交易列表
    builder
      // .addCase(getList.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(getList.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // state.data = action.payload.transactions;
      //   // state.pagination = {
      //   //   current: action.payload.pageIndex,
      //   //   pageSize: action.payload.pageSize,
      //   //   total: action.payload.total,
      //   // };
      // })
      // .addCase(getList.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message || '获取交易列表失败';
      // })
      // 获取交易详情
      .addCase(getTransactionInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactionInfo.fulfilled, (state, action) => {
        state.loading = false;
        // 可以根据需要更新状态
      })
      .addCase(getTransactionInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || '获取交易详情失败';
      })
      // 获取区块中的交易
      .addCase(getBlockTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlockTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.transactions;
      })
      .addCase(getBlockTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || '获取区块交易失败';
      })
      // 发送交易
      .addCase(sendTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendTransaction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || '发送交易失败';
      })
      // 转账交易
      .addCase(transfer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(transfer.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(transfer.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || '转账失败';
      });
  },
});
export const selectTransaction = (state: RootState) => state.global.transaction;

export default transactionSlice.reducer;
