import React from 'react';
import { Drawer, Menu, Button } from 'tdesign-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuFoldIcon, MenuIcon } from 'tdesign-icons-react';
import type { MenuValue } from 'tdesign-react';
import { MENU_ITEMS } from 'layouts/utils';

const { MenuItem } = Menu;

interface DrawerMenuProps {
  theme: 'dark' | 'light' | 'system' | 'blue';
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ theme }) => {
  const [visible, setVisible] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuChange = React.useCallback(
    (value: MenuValue) => {
      if (typeof value === 'string') {
        navigate(value);
        setVisible(false); // 导航后关闭抽屉
      }
    },
    [navigate]
  );

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const logo = (
    <div
      style={{
        fontSize: '20px',
        fontWeight: 'bold',
        // color: theme === 'dark' ? '#fff' : 'inherit',
        padding: '16px',
        borderBottom: `1px solid ${theme === 'dark' ? '#333' : '#e5e5e5'}`,
      }}
    >
      {t('header.title')}
    </div>
  );

  return (
    <div className="drawer-menu-container">
      <Button
        variant="text"
        shape="square"
        icon={<MenuFoldIcon />}
        onClick={toggleDrawer}
        className="mobile-menu-button"
        // theme={theme === 'light' ? 'default' : 'primary'}
      />
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        placement="left"
        size="300px"
        header={logo}
        footer={null}
        closeOnOverlayClick
        destroyOnClose={false}
      >
        <Menu
          value={location.pathname}
          onChange={handleMenuChange}
          // theme={theme === 'dark' ? 'dark' : 'light'}
          style={{ width: '100%' }}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.path} value={item.path}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Menu>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;