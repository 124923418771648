/* eslint-disable camelcase */
import OpenChainSDK, { TransactionSignParams } from 'openchain-sdk-yxl';
import { Buffer } from 'buffer';

const client = new OpenChainSDK({
  host: 'https://nodeapi.cddao.com', // 修改为完整的HTTP URL
  // host: 'http://localhost/node/api',
  chainID: 10000,
});

console.log('client:', client);

// 定义错误类型
class TransactionError extends Error {
  details: any;

  constructor(message: string, details?: any) {
    super(message);
    this.name = 'TransactionError';
    this.details = details;
  }
}

// 账户相关操作
export async function creatAccount() {
  console.log('创建账户:');
  try {
    const account = await client.account.create();
    console.log('账户创建成功:', account);
    return account;
  } catch (error) {
    console.error('创建账户失败:', error);
    throw error;
  }
}

export async function validateAddress(address: string) {
  console.log('获取账户信息:', address);
  try {
    const info = await client.account.checkValid(address);
    console.log('账户信息:', info);
    return info;
  } catch (error) {
    console.error('获取账户信息失败:', error);
    throw error;
  }
}

export async function getAccountInfo(address: string) {
  console.log('获取账户信息:', address);
  try {
    const info = await client.account.getInfo(address);
    console.log('账户信息:', info);
    return info;
  } catch (error) {
    console.error('获取账户信息失败:', error);
    throw error;
  }
}

export async function getAccountBalance(address: string) {
  console.log('获取账户余额:', address);
  try {
    const balance = await client.account.getBalance(address);
    console.log('账户余额:', balance);
    return balance;
  } catch (error) {
    console.error('获取账户余额失败:', error);
    throw error;
  }
}

export async function getAccountMetadata(address: string, metadataKey: string) {
  console.log('获取账户余额:', address);
  const params = {
    address,
    key: metadataKey,
  };
  try {
    const metadata = await client.account.getMetadata(params);
    console.log('账户余额:', metadata);
    return metadata;
  } catch (error) {
    console.error('获取账户余额失败:', error);
    throw error;
  }
}

// 区块相关操作
export async function getNumber() {
  console.log('获取当前区块高度');
  try {
    const height = await client.block.getNumber();
    console.log('当前区块高度:', height);
    return height;
  } catch (error) {
    console.error('获取区块高度失败:', error);
    throw error;
  }
}

export async function getBlockInfo(height: string) {
  console.log('获取区块信息:', height.toString());
  const heightStr = height.toString();
  try {
    const info = await client.block.getInfo(heightStr);
    console.log('区块信息:', info);
    return info;
  } catch (error) {
    console.error('获取区块信息失败:', error);
    throw error;
  }
}

export async function getLatestBlock() {
  console.log('获取最新区块');
  try {
    const block = await client.block.getLatestInfo();
    console.log('最新区块:', block);
    return block;
  } catch (error) {
    console.error('获取最新区块失败:', error);
    throw error;
  }
}

export async function getBlockTransactions(blockNumber: string) {
  console.log('获取最新区块', blockNumber);
  try {
    const block = await client.block.getTransactions(blockNumber);
    console.log('最新区块:', block);
    return block;
  } catch (error) {
    console.error('获取最新区块失败:', error);
    throw error;
  }
}

// 交易相关操作
export async function getTransactionInfo(hash: string) {
  console.log('获取交易信息:', hash);
  try {
    const info = await client.transaction.getInfo(hash);
    console.log('交易信息:', info);
    return info;
  } catch (error) {
    console.error('获取交易信息失败:', error);
    throw error;
  }
}

export async function signTransaction({ privateKeys, blob }: TransactionSignParams) {
  console.log('获取交易信息:', privateKeys);
  try {
    const info = await client.transaction.sign({ privateKeys, blob });
    console.log('交易信息:', info);
    return info;
  } catch (error) {
    console.error('获取交易信息失败:', error);
    throw error;
  }
}

export async function sendTransaction(tx: { signature: any; blob: string }) {
  console.log('发送交易:', tx);
  try {
    const result = await client.transaction.submit({
      blob: tx.blob,
      signature: tx.signature,
    });
    console.log('交易发送成功:', result);
    return result;
  } catch (error) {
    console.error('发送交易失败:', error);
    throw error;
  }
}

// 合约相关操作
// TODO: 未完成
export async function checkContract(contractAddress: string) {
  console.log('部署合约');
  try {
    const result = await client.contract.checkValid(contractAddress);
    console.log('合约部署成功:', result);
    return result;
  } catch (error) {
    console.error('部署合约失败:', error);
    throw error;
  }
}

export async function getContractAddress(hash: string) {
  console.log('获取合约地址');
  try {
    const result = await client.contract.getAddress(hash);
    console.log('获取合约地址 0:', result);
    return result;
  } catch (error) {
    console.error('获取合约地址 1:', error);
    throw error;
  }
}

export async function getContractInfo(contractAddress: string) {
  console.log('获取合约信息', contractAddress);
  try {
    const result = await client.contract.getInfo(contractAddress);
    console.log('获取合约信息:', result);
    return result;
  } catch (error) {
    console.error('获取合约信息:', error);
    throw error;
  }
}

export async function callContract(address: string, method: string) {
  console.log('调用合约:', address, method);
  const args = {
    optType: 1,
  };
  try {
    const result = await client.contract.call(args);
    console.log('合约调用成功:', result);
    return result;
  } catch (error) {
    console.error('调用合约失败:', error);
    throw error;
  }
}

export async function deployContract(hash: string) {
  console.log('部署合约');
  try {
    const result = await client.contract.getAddress(hash);
    console.log('合约部署成功:', result);
    return result;
  } catch (error) {
    console.error('部署合约失败:', error);
    throw error;
  }
}

// 验证者相关操作
// export async function getValidators() {
//   console.log('获取验证者列表');
//   try {
//     const validators = await client.validator.getList();
//     console.log('验证者列表:', validators);
//     return validators;
//   } catch (error) {
//     console.error('获取验证者列表失败:', error);
//     throw error;
//   }
// }

// export async function getValidatorInfo(address: string) {
//   console.log('获取验证者信息:', address);
//   try {
//     const info = await client.validator.getInfo(address);
//     console.log('验证者信息:', info);
//     return info;
//   } catch (error) {
//     console.error('获取验证者信息失败:', error);
//     throw error;
//   }
// }

// 同步数据库API获取
export async function getBlockList({ current, pageSize }: { current: number; pageSize: number }) {
  console.log('获取区块信息:', current, pageSize);
  try {
    const info = await client.block.getLatestInfo();
    console.log('区块信息:', info);
    return info;
  } catch (error) {
    console.error('获取区块信息失败:', error);
    throw error;
  }
}

export async function getTrxs({ current, pageSize }: { current: number; pageSize: number }) {
  console.log('获取区块信息:', current, pageSize);
  try {
    const info = await client.block.getLatestInfo();
    console.log('区块信息:', info);
    return info;
  } catch (error: any) {
    console.error('获取区块信息失败:', error);
    if (error.message?.includes('Network Error') || error.message?.includes('blocked by CORS policy')) {
      throw new TransactionError('网络连接失败，请检查网络连接或等待服务恢复', error);
    }
    throw new TransactionError(error.message || '获取区块信息失败，请稍后重试', error);
  }
}
export async function getOperationData(operationType: string, transferData: any): Promise<any> {
  switch (operationType) {
    case '1': {
      const activeateArgs = {
        sourceAddress: transferData.from,
        destAddress: transferData.to,
        initBalance: (transferData.amount * 10000000).toString(),
        metadata: transferData.metadata,
      };
      // 激活账号
      const operationResult = await client.operation.accountActivateOperation(activeateArgs);
      return operationResult.result.operation;
    }
    case '11': {
      // 转移gas同时激活合约地址
      const args = {
        sourceAddress: transferData.from,
        contractAddress: transferData.to,
        opAmount: (transferData.amount * 10000000).toString(),
        input: transferData.input,
        // metadata: transferData.metadata,
      };
      const operationResult = await client.operation.contractInvokeByOPOperation(args);
      return operationResult.result.operation;
    }
    case '111': {
      // 发行资产同时激活合约地址
      const args = {
        sourceAddress: transferData.from,
        contractAddress: transferData.to,
        assetAmount: (transferData.amount * 10000000).toString(),
        code: transferData.code,
        issuer: transferData.issuer,
        input: transferData.input,
        metadata: transferData.metadata,
      };
      const operationResult = await client.operation.contractInvokeByAssetOperation(args);
      return operationResult.result.operation;
    }
    case '1111': {
      // 合约地址已经存在，进行部署合约
      const args = {
        sourceAddress: transferData.from,
        initBalance: (transferData.amount * 10000000).toString(), // 初始余额
        type: transferData.codeType,
        payload: transferData.payload,
        initInput: (transferData.initInput).toString(),
        // metadata: transferData.metadata,
      };
      const operationResult = await client.operation.contractCreateOperation(args);
      // console.log('合约创建operationResult:', operationResult);
      return operationResult.result.operation;
    }
    case '2': {
      // 发行资产
      const issueArgs = {
        sourceAddress: transferData.from,
        assetAmount: (transferData.amount * 10000000).toString(),
        code: transferData.code,
      };
      // 使用 async/await 处理 Promise
      const operationResult = await client.operation.assetIssueOperation(issueArgs);
      return operationResult.result.operation;
    }
    case '3': {
      // type: 'payAsset',
      const payArgs = {
        sourceAddress: transferData.from,
        destAddress: transferData.to,
        code: transferData.code,
        issuer: transferData.issuer,
        assetAmount: (transferData.amount * 10000000).toString(),
      };
      // 使用 async/await 处理 Promise
      const operationResult = await client.operation.assetSendOperation(payArgs);
      return operationResult.result.operation;
    }
    case '4': {
      // 设置 metadata
      const metadataArgs = {
        key: transferData.key,
        value: transferData.value,
        version: transferData.version,
        deleteFlag: transferData.deleteFlag,
      };
      // 使用 async/await 处理 Promise
      const operationResult = await client.operation.accountSetMetadataOperation(metadataArgs);
      return operationResult.result.operation;
    }
    case '5': {
      // 设置权重
      const setSignerWeightArgs = {
        key: transferData.key,
        value: transferData.value,
        version: transferData.version,
        deleteFlag: transferData.deleteFlag,
      };
      // 使用 async/await 处理 Promise
      const operationResult = await client.operation.accountSetMetadataOperation(setSignerWeightArgs);
      return operationResult.result.operation;
    }
    case '6': {
      // 设置阈值
      const setThresholdArgs = {
        key: transferData.key,
        value: transferData.value,
        version: transferData.version,
        deleteFlag: transferData.deleteFlag,
      };
      // 使用 async/await 处理 Promise
      const operationResult = await client.operation.accountSetMetadataOperation(setThresholdArgs);
      return operationResult.result.operation;
    }
    case '7': {
      // gas转移
      const payCoinArgs = {
        sourceAddress: transferData.from,
        destAddress: transferData.to,
        opAmount: (transferData.amount * 10000000).toString(),
        metadata: transferData.metadata,
      };
      const operationResult = await client.operation.opSendOperation(payCoinArgs);
      return operationResult.result.operation;
    }
    case '8': {
      // log
      const args = {
        sourceAddress: transferData.from,
        topic: transferData.topic,
        data: transferData.data,
        metadata: transferData.metadata,
      };
      const operationResult = await client.operation.logCreateOperation(args);
      // console.log('logCreateOperation:', operationResult);
      return operationResult.result.operation;
    }
    case '9': {
      // 设置权限
      const setPrivilegeArgs = {
        sourceAddress: transferData.from,
        masterWeight: transferData.masterWeight,
        signers: transferData.signers,
        txThreshold: transferData.txThreshold,
        typeThresholds: transferData.typeThresholds,
      };
      const operationResult = await client.operation.accountSetPrivilegeOperation(setPrivilegeArgs);
      return operationResult.result.operation;
    }
    default:
      throw new Error(`不支持的操作类型: ${operationType}`);
  }
}

export async function getNonce(address: string): Promise<any> {
  return client.account.getNonce(address).then((res) => res.result.nonce);
}

export async function evaluateFee(preTransactionInfo: any): Promise<any> {
  return client.transaction.evaluateFee(preTransactionInfo)
}

export function getFeeLimitWithRetry(preTransactionInfo: any, maxRetries = 3, delayMs = 2000) {
  return new Promise<{ feeLimit: string; gasPrice: string }>((resolve, reject) => {
    let attempts = 0;

    const attemptCalculation = () => {
      evaluateFee(preTransactionInfo)
        .then((fee) => {
          console.log('交易费计算结果:', fee);
          if (fee?.result?.feeLimit) {
            resolve({
              feeLimit: fee.result.feeLimit,
              gasPrice: fee.result.gasPrice,
            });
          } else if (attempts < maxRetries - 1) {
            attempts += 1;
            setTimeout(attemptCalculation, delayMs);
          } else {
            reject(new Error('计算交易费失败'));
          }
        })
        .catch((err) => {
          console.warn(`计算交易费重试 ${attempts + 1}/${maxRetries}:`, err);
          if (attempts < maxRetries - 1) {
            attempts += 1;
            setTimeout(attemptCalculation, delayMs);
          } else {
            reject(err);
          }
        });
    };

    attemptCalculation();
  })
};

export async function buildBold(preTransactionInfo: any, transferData: any, nonce: any, newOperation: any) {
  const { feeLimit, gasPrice } = await getFeeLimitWithRetry(preTransactionInfo);
  // 需要等待一段时间，等待交易费计算完成
  console.log('交易序列化参数:', preTransactionInfo, feeLimit);
  const transactionInfo = {
    sourceAddress: transferData.from, // 发起地址
    nonce: (Number(nonce) + 1).toString(), // 随机数
    feeLimit: Number(feeLimit).toString(), // 交易费
    gasPrice: Number(gasPrice).toString(), // 矿工费
    operations: [newOperation],
    // ceilLedgerSeq: '10000', // 最大区块高度
    // metadata: transferData.metadata, // 自定义备注      feeLimit: feeLimit.result.feeLimit,
  };
  // 序列化交易
  const serializedTx = await client.transaction.buildBlob(transactionInfo);

  console.log('交易序列化成功:', typeof serializedTx, serializedTx);
  const blobUnit8Array = serializedTx.result.transactionBlob;
  const boldHexString = Buffer.from(blobUnit8Array, 'hex').toString('hex');
  return boldHexString;
}


// 交易，先进行序列化，然后进行签名，然后发送交易
// https://gitee.com/redblow/bm-ftchain/blob/master/chain/docs/api.md#%E8%BD%AC%E7%A7%BB%E8%B5%84%E4%BA%A7
// 代码值	 枚举名	          说明
// 1	    CREATE_ACCOUNT	创建账号
// 2	    ISSUE_ASSET	    发行资产
// 3	    PAY_ASSET	      转移资产
// 4	    SET_METADATA	  设置metadata
// 7	    PAY_COIN	      支付
// 9	    SET_PRIVILEGE	  设置权限
/**
 * 执行区块链转账交易
 * 支持多种操作类型：
 * 1. 账户激活
 * 2. 资产发行
 * 3. 资产转移
 * 4. 设置元数据
 * 5. 合约调用
 * 6. 合约创建
 * 7. Gas转移
 * 8. 日志创建
 * 9. 设置权限
 *
 * @param transferData - 转账交易参数
 * @param transferData.from - 发送方地址
 * @param transferData.to - 接收方地址
 * @param transferData.amount - 转账金额
 * @param transferData.type - 操作类型(1-9)
 * @param transferData.privateKey - 发送方私钥
 * @param transferData.metadata - 交易备注(可选)
 * @returns 交易结果
 * @throws {TransactionError} 当交易失败时抛出错误
 */
export async function transfer(transferData: any) {

  const nonce = await getNonce(transferData.from);
  const lastBlockNumber = await getLatestBlock().then((res) => res.result.number);
  console.log('nonce:', nonce, lastBlockNumber);

  try {
    const newOperation = await getOperationData(transferData.type, transferData);
    const preTransactionInfo = {
      sourceAddress: transferData.from, // 发起地址
      nonce: (Number(nonce) + 1).toString(), // 随机数
      operations: [newOperation],
      signtureNumber: '1', // 签名数量
      // ceilLedgerSeq: lastBlockNumber.toString(), // 最大区块高度
      // metadata: Buffer.from('{"bar":"foo"}').toString('hex'), // 自定义备注
    };

    // 构建交易
    const boldHexString = await buildBold(preTransactionInfo, transferData, nonce, newOperation);

    // 签名交易
    const signData = {
      privateKeys: [transferData.privateKey],
      blob: boldHexString,
    }
    const signedTx = await signTransaction(signData);
    // console.log('交易签名:', signedTx);

    const trxPreload = {
      signature: signedTx.result.signatures,
      blob: boldHexString,
    };
    // console.log('交易预加载:', trxPreload);

    // 发送交易
    const result = await sendTransaction(trxPreload);
    console.log('交易发送:', result);
    // wsClient.subscribeAddresses(['YxLVdMpKLkRLYG6LKed6DQ9yzprnpRdusz28m', 'YxLW1Vb5sz2DnaNtY3RdVV5XcvVQJKobiFEPz']);

    return result;
  } catch (error: any) {
    console.error('转账交易失败:', error);

    // 如果是已经处理过的错误，直接抛出
    if (error instanceof TransactionError) {
      throw error;
    }

    // 处理其他未知错误
    throw new TransactionError(error.message || '转账交易失败', error);
  }
}
