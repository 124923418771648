import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Tabs, Card, MessagePlugin } from "tdesign-react";
import { LockOnIcon, UserIcon } from "tdesign-icons-react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setLoginState, setUserInfo } from "../../store/slices/globalSlice";
import "./styles/index.css";
import { menuItemsSider } from "layouts/utils";

const { TabPanel } = Tabs;
const { FormItem } = Form;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("qrcode");

  const onSubmit = async (e: any) => {
    if (e.validateResult === true) {
      try {
        const formData = form.getFieldsValue(true);
        console.log("Form submitted:", formData);
        // 私钥登录，私钥创建keystore
        

        // 设置登录状态和用户信息
        dispatch(setLoginState(true));
        dispatch(
          setUserInfo({
            address: formData.address,
            // privateKey: formData.privateKey,
          })
        );

        MessagePlugin.success("登录成功");
        const { path } = menuItemsSider[0];
        navigate(path);
      } catch (error: any) {
        dispatch(setLoginState(false));
        dispatch(setUserInfo(null));
        MessagePlugin.error(error.message || "登录失败");
      }
    }
  };

  const handleTabChange = (value: any) => {
    setActiveTab(value);
    form.reset();
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <h2 className="login-title">登录</h2>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <TabPanel value="qrcode" label="扫码登录">
            <div className="qrcode-container">
              {/* <QRCode value="https://example.com" /> */}
              <p>请使用移动端扫描二维码登录</p>
            </div>
          </TabPanel>

          <TabPanel value="privateKey" label="私钥登录">
            <Form form={form} onSubmit={onSubmit} className="login-form">
              <FormItem
                name="privateKey"
                rules={[{ required: true, message: "请输入私钥" }]}
              >
                <Input
                  type="password"
                  prefixIcon={<LockOnIcon />}
                  placeholder="请输入私钥"
                  clearable
                />
              </FormItem>
              <FormItem>
                <Button theme="primary" type="submit" block>
                  登录
                </Button>
              </FormItem>
            </Form>
          </TabPanel>

          <TabPanel value="address" label="地址登录">
            <Form form={form} onSubmit={onSubmit} className="login-form">
              <FormItem
                name="address"
                rules={[{ required: true, message: "请输入地址" }]}
              >
                <Input
                  prefixIcon={<UserIcon />}
                  placeholder="请输入地址"
                  clearable
                />
              </FormItem>
              <FormItem
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input
                  type="password"
                  prefixIcon={<LockOnIcon />}
                  placeholder="请输入密码"
                  clearable
                />
              </FormItem>
              <FormItem>
                <Button theme="primary" type="submit" block>
                  登录
                </Button>
              </FormItem>
            </Form>
          </TabPanel>
        </Tabs>
        <div className="login-footer">
          <Button
            theme="default"
            variant="text"
            onClick={() => navigate("/register")}
          >
            注册新账户
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Login;
